.ui.input>input,
body {
    font-family: Arial, Helvetica, sans-serif !important
}

@keyframes popup {
    0% {
        transform: scale(0)
    }

    to {
        transform: scale(1)
    }
}

.logo-header[data-background-color=wsp],
.navbar-header[data-background-color=red2] {
    background: #fc4237 !important
}

.sidebar.sidebar-style-2 .nav.nav-primary>.nav-item.active>a {
    background: #fc4237 !important;
    margin-bottom: 0 !important;
    cursor: pointer !important
}

.sidebar .nav>.nav-item a,
.sidebar[data-background-color=white] .nav>.nav-item a {
    margin-bottom: 0 !important
}

.table td,
.table th {
    height: 31px !important
}

.requiredField {
    border-bottom-color: red !important;
    border-bottom-width: 2px !important
}

.form-control:disabled,
.form-control[readonly] {
    opacity: 1 !important
}

.card .card-action,
.card .card-header,
.card-light .card-action,
.card-light .card-header {
    padding: .5rem !important
}

.ui-autocomplete {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    float: left;
    display: none;
    min-width: 160px;
    padding: 4px 0;
    margin: 2px 0 0;
    list-style: none;
    background-color: #fff;
    border-color: #ccc;
    border: 1px solid rgba(0, 0, 0, .2);
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
    -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box
}

.floatButton,
.reviewsidebar,
.sidenav {
    position: fixed
}

.ui-menu-item>a.ui-corner-all {
    display: block;
    padding: 3px 15px;
    clear: both;
    font-weight: 400;
    line-height: 18px;
    color: #555;
    white-space: nowrap
}

.ui-state-active,
.ui-state-hover {
    color: #fff;
    text-decoration: none;
    border: 1px solid #48abf7 !important;
    background-color: #48abf7 !important;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    background-image: none
}

.ui-state-focus {
    background-color: #48abf7 !important;
    border: 0 !important
}

.ui-menu .ui-menu-item:hover>* {
    border: 1px solid #48abf7 !important;
    background: #48abf7 !important;
    font-weight: 400;
    color: #fff
}

.ui-menu-item a.ui-state-focus {
    background: #48abf7 !important
}

table.dataTable {
    border-collapse: collapse !important
}

div.dataTables_wrapper div.dataTables_filter input {
    width: 275px !important
}

.page-inner {
    padding-bottom: 0 !important;
    padding: .5rem
}

.pagination>li:first-child>a,
.pagination>li:first-child>span,
.pagination>li:last-child>a,
.pagination>li:last-child>span,
.pagination>li>a,
.pagination>li>span {
    border-radius: 0 !important;
    margin: 0 !important
}

.page-item.active .page-link {
    background-color: #48abf7 !important;
    border-color: #48abf7 !important
}

.main-header {
    min-height: 42px
}

.logo-header {
    height: 44px;
    line-height: 44px
}

.main-panel>.content {
    margin-top: 44px
}

.main-header .navbar-header {
    min-height: 44px
}

.Toastify__toast-container,
.sidebar,
.sidebar[data-background-color=white] {
    margin-top: 30px
}

.nav-search .form-control {
    font-size: 11px
}

.logo-header .logo .navbar-brand {
    height: 35px;
    width: 80px;
    margin-left: 42px
}

@media (min-width:576px) {
    .page-inner {
        padding-right: 1rem;
        padding-left: 1rem
    }
}

@media (min-width:992px) {
    .page-inner {
        padding-right: .5rem;
        padding-left: .5rem
    }
}

@media screen and (max-width:991px) {
    .sidebar {
        margin-top: 0
    }
}

.form-control-sm,
.input-group-sm>.form-control,
.input-group-sm>.input-group-append>.btn,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn,
.input-group-sm>.input-group-prepend>.input-group-text {
    padding: .25rem .5rem !important;
    font-size: 12px !important;
    font-weight: 700 !important;
    border: 1px solid rgba(34, 36, 38, .15)
}

.chosen-container-single .chosen-single {
    height: 28px;
    line-height: 28px
}

.card .card-body,
.card-light .card-body {
    padding: 0.75rem 1.25rem;
}

.form-check,
.form-group {
    padding: 1.5px !important
}

.sidebar.sidebar-style-2 .nav .nav-item {
    padding: 1px 5px
}

.sidebar.sidebar-style-2 .nav .nav-item a {
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer !important;
    margin-left: -2px
}

.sidebar .nav-collapse li a,
.sidebar[data-background-color=white] .nav-collapse li a {
    margin-bottom: 3px !important;
    padding: 5px 15px !important
}

.sidebar .nav-collapse,
.sidebar[data-background-color=white] .nav-collapse {
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 4px;
    padding-top: 2px
}

.sidebar .nav,
.sidebar[data-background-color=white] .nav {
    margin-top: 5px
}

.card .card-header,
.card-light .card-header {
    padding-top: 3px !important;
    padding-bottom: 3px !important
}

.select2-container--spreadSheetSelect2 .select2-results__option {
    padding: 6px;
    user-select: none;
    -webkit-user-select: none;
    height: 22px;
    line-height: .5;
    white-space: nowrap
}

.select2-container--spreadSheetSelect2 .select2-selection__arrow b {
    border-color: #888 transparent transparent;
    border-style: solid;
    border-width: 5px 4px 0;
    height: 0;
    left: 97%;
    margin-left: -6px;
    margin-top: -5px;
    position: absolute;
    top: 50%;
    width: 0
}

.form-check label,
.form-group label {
    margin-bottom: 0;
    color: #495057;
    font-size: 12px !important;
    white-space: nowrap
}

.nav-tabs .nav-link.active {
    background: #fc4237 !important;
    border: 1px solid #fc4237 !important;
    color: #fff;
    cursor: pointer
}

.nav-tabs .nav-link {
    height: 28px !important;
    margin-right: 4px !important;
    padding-bottom: 20px !important;
    padding-top: 4px !important;
    margin-bottom: 4px !important;
    cursor: pointer;
    border: 1px solid rgba(34, 36, 38, .15);
    border-bottom-color: transparent !important;
    background-color: #fc423705 !important
}

.tab-content>.tab-pane {
    min-height: 220px !important
}

.drop-up .Select-menu-outer {
    top: auto;
    bottom: 100%
}

.form-group>.ui.active.search.dropdown input.search:focus+.text {
    color: rgb(115 115 115/2%) !important
}

.disabledControl,
.form-group .ui.disabled.dropdown,
.ui.disabled.input,
.ui.dropdown .menu>.disabled.text,
.ui.input:not(.disabled) input[disabled] {
    opacity: 1 !important;
    color: rgb(0 0 0/57%) !important;
    background-color: #d3d3d3 !important;
    border: 0 !important
}

.card-title,
.customSectionHeader,
.ui.checkbox input:checked~.box:after,
.ui.checkbox input:checked~label:after {
    color: #fc4237 !important
}

.ui.disabled.dropdown,
.ui.dropdown .menu>.disabled.item {
    opacity: 1 !important;
    color: rgb(28 27 27/55%) !important;
    background-color: #d3d3d33b !important;
    border: 0 !important
}

.customSectionHeader {
    padding: 7px 5px 7px 15px !important;
    font-size: 18px !important;
    background-color: rgba(0, 0, 0, .05) !important
}

.card-title,
.customSection,
.uiminilabel {
    font-size: 12px !important
}

.customSectionHeaderIcon {
    margin-right: 4px
}

.customSectionMain {
    width: 100% !important
}

.customSection {
    padding: 7px 5px !important
}

.sidenav {
    z-index: 1
}

.sidenav a {
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    font-size: 25px;
    color: #2196f3;
    display: block
}

.sidenav a:hover {
    color: #064579
}

.main {
    margin-left: 140px;
    padding: 0 10px
}

@media screen and (max-height:450px) {
    .sidenav {
        padding-top: 15px
    }

    .sidenav a {
        font-size: 18px
    }
}

.customSectionMain>ul {
    padding: 0
}

::-webkit-scrollbar {
    width: 10px
}

::-webkit-scrollbar-track {
    background: #f1f1f1
}

::-webkit-scrollbar-thumb {
    background: #888
}

::-webkit-scrollbar-thumb:hover {
    background: #555
}

.card,
.card-light {
    border-radius: 5px;
    background-color: #fff;
    margin-bottom: 5px;
    box-shadow: 0 1px 2px 0 rgb(34 36 38/15%) !important;
    border: 1px solid rgba(34, 36, 38, .15) !important
}

.card-title>i {
    margin-right: 5px
}

.card-header {
    background: rgba(0, 0, 0, .05) !important
}

.uiminilabel {
    font-weight: 700
}

.reviewmain {
    padding-left: 35px
}

.form-control:hover,
input:focus {
    border-color: #85b7d9 !important;
    box-shadow: none
}

.floatButton {
    bottom: 0
}

.floatButtonCard {
    align-items: flex-end;
    border: 0 !important;
    margin-bottom: 0 !important
}

.meButton,
.meButton:hover {
    background-color: #fc4237 !important;
    color: #fff !important
}

.meButton:hover {
    background-color: #fc4237e0 !important;
    background-image: none !important;
    box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgb(34 36 38/15%) inset !important
}

.sidebar .nav>.nav-item a[aria-expanded=true] .caret {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg)
}

.select2-container--spreadSheetSelect2 .select2-dropdown--below {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: -12px;
    z-index: 100
}

.select2-container--spreadSheetSelect2 .select2-results__option--highlighted[aria-selected] {
    background-color: #fc4237;
    color: #fff;
    height: 22px
}

.select2-container--spreadSheetSelect2 .select2-search--dropdown .select2-search__field {
    border: 1px solid #aaa;
    height: 22px
}

.select2-container--spreadSheetSelect2 .select2-selection--single .select2-selection__rendered {
    color: #444;
    background-color: #ffffe7 !important;
    line-height: 20px !important;
    font-style: normal;
    font-weight: 700;
    font-size: 11.7px;
    font-family: Arial
}

.font-weight-bold>label,
.ui.input>input {
    font-weight: 700 !important
}

.select2-container--spreadSheetSelect2 .select2-results {
    max-height: 350px;
    overflow-x: hidden;
    overflow-y: auto;
    font-style: normal;
    font-weight: 700;
    font-size: 11.7px;
    font-family: Arial
}

.select2-dropdown {
    background-color: #ffffe7 !important
}

.dimmer>.modal {
    top: initial !important;
    right: initial !important;
    bottom: initial !important;
    left: initial !important;
    border-top: 7px solid #e74c3c !important;
    animation: .2s popup
}

.ui.dimmer {
    background-color: rgb(0 0 0/1%) !important
}

.ui.tiny.modal {
    width: 400px !important
}

.unauthorized-Page-Icon {
    font-size: 43px
}

.Toastify__toast-body,
.ui.input>input {
    font-size: 12px !important
}

@media screen and (min-width:0px) {
    .sidebar_minimize .sidebar {
        width: 55px;
        transition: .3s
    }
}

.input-group-text {
    border-color: rgba(0, 0, 0, .125) !important
}

.input-group>.custom-select:not(:first-child),
.input-group>.form-control:not(:first-child) {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-color: rgba(0, 0, 0, .125) !important
}

.input-group-sm>.form-control,
.input-group-sm>.input-group-append>.btn,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn,
.input-group-sm>.input-group-prepend>.input-group-text {
    height: calc(1.8125rem + 8px) !important;
    max-height: 34px !important;
    border-radius: .12rem !important
}

.themeTextColor {
    background: #fc4237 !important;
    color: #fff !important
}

.dimmer :target .modal {
    animation: .7s popup
}

body::-webkit-scrollbar {
    width: .4em
}

body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px #fc4237
}

body::-webkit-scrollbar-thumb {
    background-color: #fc4237;
    /* outline: solid 1px */
}

.form-control-sm {
    max-height: 27px;
    min-height: 27px;
    margin: 0;
    min-width: 100%;
    flex: 1 0 auto;
    outline: 0;
    -webkit-tap-highlight-color: transparent;
    text-align: left;
    background: #fff !important;
    color: rgba(0, 0, 0, .87);
    border-radius: .28571429rem !important;
    transition: box-shadow .1s, border-color .1s;
    box-shadow: none
}

.gc-floatingobject-content-container {
    z-index: 0 !important
}

.Toastify__toast {
    min-height: 48px !important
}

.Toastify__toast-container {
    width: auto !important;
    min-width: 300px !important
}

.ui.selection.dropdown {
    line-height: .49em !important;
    min-height: 27px !important
}

.ui.multiple.dropdown>.label,
.ui.selection.dropdown {
    font-size: 12px !important;
    font-weight: 700 !important
}

.ui.fluid.dropdown>.dropdown.icon {
    padding-top: 6px !important;
    padding-bottom: 6px !important
}

.ui.input>input,
.ui.mini.label,
.ui.mini.labels .label {
    height: 27px !important
}

.ui.search.selection.dropdown>input.search {
    top: -4px !important
}

.ui.multiple.search.dropdown,
.ui.multiple.search.dropdown>input.search {
    padding-top: 0 !important;
    padding-bottom: 0 !important
}

.ui.input>input {
    line-height: 0 !important;
    min-height: 27px !important
}

.ui.selection.dropdown .menu>.item>span {
    margin-left: -5px
}

body ::-webkit-scrollbar-thumb {
    border-radius: 0 !important
}

.card-body-projectdetails {
    background-color: #a9a9a9
}

#projectReviewForm {
    scroll-behavior: smooth;
    height: calc(100vh - 292px);
    overflow-y: auto;
    overflow-x: hidden
}

.min-logo {
    height: 35px;
    width: 80px;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 5px;
    border-right: 2px solid #fff
}

.navbar {
    display: flex;
    align-items: center;
    align-content: space-around !important;
    flex-wrap: nowrap !important
}

.cardProjectReviewForm {
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 0
}

.applicationName {
    color: #fff;
    font-weight: 700;
    width: 100%;
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
    text-align: initial;
    padding-left: 5px
}

.customIconBtn {
    padding: 0 !important;
    color: #1e252b !important;
    font-weight: 700 !important
}

.avatar-circle {
    border-radius: 20% !important
}

.navbar-header {
    padding: 0 3px
}

.btn-toggle {
    height: 35px;
    width: 35px;
    border: none !important
}

.btn-toggle:hover {
    background-color: #24242417 !important;
    border-radius: 20% !important;
    border: none !important;
    color: white !important;
}

.sidebar .user .info a>span .user-level,
.sidebar[data-background-color=white] .user .info a>span .user-level {
    font-size: 10px !important;
    margin-top: -3px !important
}

@media screen and (min-width:991px) {
    .main-panel.full-height .navbar-header {
        min-height: 44px
    }

    .logo-header {
        line-height: 44px
    }

    .sidebar_minimize .sidebar {
        width: 55px;
        transition: .3s
    }

    .sidebar_minimize .sidebar .sidebar-wrapper {
        width: 59px;
        transition: .3s
    }

    .sidebar_minimize .unauthenticated-logo-header {
        width: 0px !important;
       
    }

    .sidebar_minimize .logo-header {
        width: 55px;
        transition: .3s;
        padding: 0;
        text-align: center
    }

    .sidebar_minimize .main-panel {
        width: calc(100% - 55px);
        transition: .3s
    }

    .sidebar_minimize .logo-header .nav-toggle {
        right: -7px !important
    }
}

.logo-header .nav-toggle {
    right: 0 !important
}

.btn-logout{
    color: #fc4237 !important;
    background-color: white !important;

}

 
 